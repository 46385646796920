<template>
  <div class="results-content">
    <div class="headnav"><i class="icon" @click="$router.go(-1)"></i>Winners Record</div>
    <div class="top">
      <div class="item">
        <div class="label">Draw ID</div>
        <div class="value">{{issuse}}</div>
      </div>
      <div class="item">
        <div class="label">Time</div>
        <div class="value">{{formatTime(opentime)}}</div>
      </div>
      <div class="item">
        <div class="label">Winning Numbers</div>
        <div class="value">
          <span class="num">{{codeArr[0]}}</span>
          <span class="num">{{codeArr[1]}}</span>
          <span class="num">{{codeArr[2]}}</span>
        </div>
      </div>
    </div>
    <PullRefresh @refresh="refresh">
      <div class="result-top">
        <div class="result">
          <table class="table">
            <thead>
            <tr class="title-th">
              <th class="a">Time</th>
              <th class="b">Phone<br/>Numbers</th>
              <th class="c">Picks</th>
              <th class="d">Wager<br/>(KSH)</th>
              <th class="e">Winnings<br/>(KSH)</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <td class="a">
                <!-- <div>{{item.actionno}}</div> -->
                <!-- <div class="theme-color">{{item.res}}</div> -->
                <div>{{formatTime(item.actiontime)}}</div>
              </td>
              <td class="b">{{item.username}}</td>
              <td class="c">
                <div class="codeHtml" v-html="item.code"></div>
                <div class="theme-color">{{item.playType==1?'Any Order':'Straight Order'}}</div>
              </td>
              <td class="d">{{item.score}}</td>
              <td class="e theme-color">{{item.returnscore}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="control-strip">
        <van-button class="button previous" @click="on_previous" v-bind:class="{button2:can_previous}">Previous
        </van-button>
        <div class="text">
          <span class="cur">{{curPage}}</span>
          <span>/</span>
          <span class="max">{{maxPage}}</span>
        </div>
        <van-button class="button next" @click="on_next" v-bind:class="{button2:can_next}">Next</van-button>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
    import {gameType, serve} from "../constant";
    import {formatDate} from "../utils/date";
    import {mapGetters} from "vuex";
    import PullRefresh from "../components/PullRefresh";

    export default {
        components: {PullRefresh},
        data() {
            return {
                curPage: 1,
                limit: 10,
                maxPage: 0,
                select_idx: 0,
                results: [],
                code: '',
                codeArr: ["-","-","-"],
                issuse: '',
                opentime: ''
            }
        },
        watch: {
            '$route' () {
              this.init();
            }
        },
        created() {
            this.init();
        },
        computed: {
            ...mapGetters([
                "drawId",
            ]),
            details() {
                return this.result[this.select_idx];
            },
            can_previous() {
                return this.curPage > 1;
            },
            can_next() {
                return this.curPage < this.maxPage;
            },

        },
        methods: {
            init(){
              console.log(this.$route.params)
              this.code = this.$route.params.code;
              this.issuse = this.$route.params.issuse;
              this.opentime = this.$route.params.opentime;
              this.codeArr = ["-","-","-"];
              this.results = [];
              if(this.code){
                this.codeArr = this.code.split(",");
                this.questResults();
              }
            },
            refresh (done) {
                let _this = this;
                setTimeout(() => {
                  _this.curPage = 1;
                  _this.questResults();
                  done()    //我就想说这里，把状态归0
                }, 500)
            },
            questResults() {
                this.$store.dispatch('serve_req', {
                    url: serve.data.winners,
                    method: 'get',
                    data: {
                        issuse: this.issuse,
                        page: this.curPage,
                        limit: this.limit,
                        type: gameType,
                        date: this.select_date_str
                    }
                }).then((data) => {
                    this.maxPage = data.pages;
                    this.results.splice(0, this.results.length);
                    data.records.forEach((item, index) => {
                        let code = this.picksStr(item);
                        item.code = code;
                        this.$set(this.results, index, item);
                    });
                })
            },
            /**
             * @param {Object} code1 开奖号码，格式：1,2,3
             * @param {Object} code2 下注号码，格式：123
             * @param {Object} playType 下注方式，1=不固定位置 2固定位置
             */
            picksStr(item) {
              let code1 = item.res;
              let code2 = item.code;
              if(code1==null){
                return code2.split("").join(",");
              }
              let playType = item.playType;
              let resHtml = "";
              let code1Arr = code1.split(",");
              if(playType==1){
                let code2Arr = code2.split("");
                let resArr = []

                //不固定位置方式的：先把固定位置的标绿，
                for(let i in code2Arr){
                  let numStr = "";
                  if(code2Arr[i]==code1Arr[i]){
                    //数字匹配，标绿
                    numStr ="<span class='winnerColor'>"+code2Arr[i]+"</span>";
                    code1Arr[i] = -1;
                    resArr[i] = numStr;
                  }else{
                    numStr = code2Arr[i];
                    resArr[i] = code2Arr[i];
                  }
                }
                console.log("code1Arr",code1Arr)
                for(let i in resArr){
                  let code = resArr[i];
                  let numStr = resArr[i];
                  if(code.length==1){
                    for(let j in code1Arr){
                      if(code==code1Arr[j]){
                        code1Arr[j] = -1;
                        //数字匹配，标绿
                        numStr ="<span class='winnerColor'>"+code+"</span>";
                      }
                    }
                  }
                  if(resHtml==""){
                    resHtml+=numStr;
                  }else{
                    resHtml+=","+numStr;
                  }
                }

              }else{
                let code2Arr = code2.split("");
                for(let i in code2Arr){
                  let numStr = "";
                  if(code2Arr[i]==code1Arr[i]){
                    //数字匹配，标绿
                    numStr = "<span class='winnerColor'>"+code2Arr[i]+"</span>";
                  }else{
                    numStr = code2Arr[i];
                  }
                  if(resHtml==""){
                    resHtml+=numStr;
                  }else{
                    resHtml+=","+numStr;
                  }
                }
              }
              return resHtml;
            },
            formatTime(time) {
                return formatDate(new Date(time), 'MM/dd hh:mm');
            },
            waiting(drawId) {
                return this.drawId === drawId;
            },
            on_previous() {
                if (this.can_previous) {
                    this.curPage--;
                    this.questResults();
                }
            },
            on_next() {
                if (this.can_next) {
                    this.curPage++;
                    this.questResults();
                }
            },
        }
    }
</script>

<style scoped lang="less">
  @import "../styles/var";

  .results-content {
    padding-bottom: 6px;
    min-height: @content-min-height;
    margin-top: 104px;
    /deep/.theme-color{
      color: @theme-color;
    }
    /deep/.winnerColor{
      color: red;
    }
    /deep/.van-picker{
      .van-picker-column,.van-picker__cancel, .van-picker__confirm,.van-picker__title{
        font-size: 24px;
      }
      .van-picker__toolbar{
        height: 60px;
      }
    }
    .top{
      background-color: #E5F2ED;
      margin: 30px;
      border-radius: 24px;
      .item{
        display: flex;
        align-items: center;
        font-size: 28px;
        padding: 20px 40px;
        .label{
          color: #737373;
        }
        .value{
          color: #3C3C3C;
          flex: 1;
          text-align: right;
          font-weight: bold;
          .num{
            display: inline-block;
            width: 72px;
            height: 72px;
            line-height: 72px;
            color: #FFF;
            background-color: #008250;
            border-radius: 50%;
            text-align: center;
            margin-left: 10px;
          }
        }
      }
    }
    .result-top {

      /deep/ .banner {
        margin: 0 @margin;
        height: 181px;
        border-radius: 10px;
        overflow: hidden;

        .van-image {
          height: 100%;
        }
      }

      .result {
        margin-top: 0;
        background: #FFFFFF;
        overflow: hidden;

        .date {
          display: flex;
          justify-content: flex-start;
          height: 110px;

          .title {
            display: flex;
            justify-items: flex-start;
            align-items: center;

            .icon {
              margin: 0 31px;
              display: block;
              width: 40px;
              height: 40px;
              background: url("../assets/img_date.png") no-repeat center;
              background-size: contain;
            }

            .date-text {
              font-size: 32px;
              font-family: Arial-BoldMT, Arial;
              font-weight: normal;
              color: #333333;
              line-height: 37px;
            }

            .icon2 {
              margin: 0 12px;
              display: block;
              width: 20px;
              height: 16px;
              background: url("../assets/img_jiantou_b.png") no-repeat center;
              background-size: contain;
            }
          }
        }

        /deep/ .date-picker {
          .van-picker__toolbar {
            height: 80px;
          }

          .van-picker-column {
            font-size: 28px;
          }

          .van-picker__cancel, .van-picker__confirm, .van-picker__title {
            font-size: 30px;
            line-height: 35px;
          }
        }

        .table {
          border-spacing: 0;
          border-collapse: collapse;

          tbody {
            display: block;
            /* max-height: calc(100vh - 650px);
            overflow-y: scroll; */
            -webkit-overflow-scrolling: touch; // 为了滚动顺畅
          }

          tr {
            height: 86px;
            border-bottom: 1px solid #EBEBEB;

            display: table;
            width: 100%;
            table-layout: fixed;
          }

          th {
            padding-right: 15px;
            text-align: left;
          }

          td {
            height: 25px;
            font-size: 26px;
            font-family: ArialMT;
            line-height: 32px;
            text-align: left;
          }

          .title-th {
            height: 86px;
            background: #FAFAFA;
            font-size: 24px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #737373;
            line-height: 26px;
            text-align: left;
            border: none;
          }

          .a {
            width: 150px;
            padding-left: @td-padding;
            .time{
              font-size: 22px;
              color: #737373;
            }
          }

          .b {
            width: 130px;
          }

          .c {
            width: 170px;
          }
          .codeHtml{
            letter-spacing:2px;
          }

          .d {
            width: 100px;
            .arrow {
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url("../assets/img_jiantou_r.png") no-repeat center;
              background-size: contain;
              margin-left: 8px;
              margin-right: 4px;
            }
          }

          .e{
            width: 120px;
          }
        }
      }
    }

    .control-strip {
      display: flex;
      justify-items: flex-start;
      justify-content: center;
      align-items: center;
      padding: 37px 0;

      .button {
        width: 132px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 19px;
        border: 1px solid #D0D0D0;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C0C0C0;
        line-height: 30px;
      }

      .button2 {
        border: 1px solid #333333;
        color: #333333;
      }

      .text {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        margin: 0 35px;

        span {
          margin: 0 3px;
        }

        .cur {
          color: #009901;
        }
      }
    }
  }

</style>
